/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const Spacer = ({
  size = 3,
  sizeVertical,
  ...rest
}: {
  size?: number | string
  sizeVertical?: number | string
}) => {
  sizeVertical = sizeVertical ? sizeVertical : size
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        mr: size,
        mb: sizeVertical,
        flexGrow: 0,
        flexShrink: 0,
      }}
      {...rest}
    />
  )
}
export default Spacer
