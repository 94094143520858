/** @jsx jsx */
import React from 'react'
import { jsx, Box, Flex, Input, Select as SelectStyled, Label } from 'theme-ui'
import { Icon } from '@components/Icons'
import { useCallback, useEffect, useRef, useState } from 'react'

import useDebounce from '@hooks/useDebounce'

interface AgeRadioButtonProps {
  icon: string
  value: string
  selection: string[]
  onChange?: (v: string[]) => any
}

const AgeRadioButton = ({
  icon,
  value,
  selection,
  onChange,
  ...rest
}: AgeRadioButtonProps) => {
  const onToggle = useCallback(() => {
    if (!onChange) return

    if (selection.indexOf(value) === -1) {
      return onChange([...selection, value])
    } else {
      return onChange(selection.filter((v) => v !== value))
    }
  }, [onChange, value, selection])

  return (
    <Icon
      name={icon as any}
      size="30px"
      color={selection.indexOf(value) === -1 ? 'grey' : 'primary'}
      colorText="black"
      {...rest}
      onClick={onToggle}
    />
  )
}

const Select = ({ options, value, handleChange, ...rest }) => {
  return (
    <SelectStyled
      value={value}
      {...rest}
      sx={{
        color: value === 'all' ? 'white' : 'primary',
        outline: 'none',
        width: '100%',
        height: '60px',
        flexGrow: 1,
        borderRadius: 0,
        border: 'none',
        px: 0,
        cursor: 'pointer',
        option: {
          color: 'black',
        },
      }}
      onChange={handleChange}
    >
      <option value="all">Alle</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectStyled>
  )
}

const Filter = ({ children, label, ...rest }) => {
  return (
    <Flex
      sx={{
        flexGrow: 1,
        bg: 'black',
        color: 'primary',
        alignItems: 'center',
        minHeight: '60px',
        px: 4,
        '&:nth-of-type(2)': {
          borderRight: [null, null, null, null, '2px solid #3A3B3D'],
          borderBottom: [
            '2px solid #3A3B3D',
            '2px solid #3A3B3D',
            '2px solid #3A3B3D',
            '2px solid #3A3B3D',
            'none',
          ],
        },
        '&:nth-of-type(3)': {
          borderRight: [null, '2px solid #3A3B3D'],
          borderBottom: ['2px solid #3A3B3D', 'none', null, null, null],
        },

        svg: {
          display: 'block',
          float: 'left',
          cursor: 'pointer',
          '&:not(:last-child)': {
            mr: 1,
          },
        },
        width: ['100%', '50%', '50%', '50%', '25%'],
      }}
      {...rest}
    >
      <Label
        htmlFor={label}
        variant="mono"
        sx={{
          fontFamily: 'mono',
          color: 'gray',
          textTransform: 'uppercase',
          flexGrow: 0,
          width: 'auto',
          mr: 3,
        }}
      >
        {label}
      </Label>
      <Box
        sx={{
          flexGrow: 1,
          'select + svg:last-child': {
            mr: 2,
          },
        }}
      >
        {children}
      </Box>
    </Flex>
  )
}

const Filters = ({
  type,
  types,
  genre,
  genres,
  setQuery,
  query,
  ages,
  setAges,
  setType,
  setGenre,
  ...rest
}) => {
  const input = useRef<HTMLInputElement>()
  const [q, setQ] = useState(query)
  const debouncedQuery = useDebounce(q.toLowerCase(), 250)

  const clearInput = useCallback(() => {
    setQ('')
    if (input.current) input.current.focus()
  }, [setQ])

  const handleInput = useCallback(
    (ev) => {
      setQ(ev.target.value)
    },
    [setQ]
  )

  useEffect(() => {
    setQuery(debouncedQuery)
  }, [debouncedQuery, setQuery])

  return (
    <Flex sx={{ flexWrap: 'wrap' }} {...rest}>
      <Flex
        sx={{
          flex: '1 1 auto',
          bg: 'white',
          alignItems: 'center',
          position: 'relative',
          width: ['100%', '50%', '50%', '50%', '25%'],
          borderRight: [null, '2px solid #3A3B3D'],
          borderBottom: [
            'none',
            '2px solid #3A3B3D',
            '2px solid #3A3B3D',
            '2px solid #3A3B3D',
            'none',
          ],
        }}
      >
        <Icon
          name="SearchIcon"
          color="gray"
          sx={{
            position: 'absolute',
            left: 3,
          }}
        />
        <Input
          placeholder="Titel, Genre, Regisseur..."
          sx={{
            pl: 6,
            m: 0,
            height: '60px',
          }}
          ref={input}
          value={q}
          onChange={handleInput}
        />
        {q.length > 0 && (
          <Icon
            name="CloseIcon"
            color="gray"
            sx={{
              position: 'absolute',
              right: 3,
              cursor: 'pointer',
            }}
            role="button"
            onClick={clearInput}
          />
        )}
      </Flex>

      <Filter label="type" sx={{ pr: 0 }}>
        <Select
          id="type"
          value={type}
          options={types}
          handleChange={(ev) => setType(ev.target.value)}
        />
      </Filter>

      <Filter label="Genre" sx={{ pr: 0 }}>
        <Select
          id="Genre"
          name="Genre"
          value={genre}
          options={genres}
          handleChange={(ev) => setGenre(ev.target.value)}
        />
      </Filter>

      <Filter label="Leeftijd">
        <AgeRadioButton
          icon="KWAl"
          value="AL"
          selection={ages}
          onChange={setAges}
        />
        <AgeRadioButton
          icon="KW6"
          value="6"
          selection={ages}
          onChange={setAges}
        />
        <AgeRadioButton
          icon="KW9"
          value="9"
          selection={ages}
          onChange={setAges}
        />
        <AgeRadioButton
          icon="KW12"
          value="12"
          selection={ages}
          onChange={setAges}
        />
      </Filter>
    </Flex>
  )
}

export default Filters
