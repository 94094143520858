/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui'
import queryString from 'query-string'
import { useState, useMemo, useEffect } from 'react'
import { GetStaticProps } from 'next'
import intersection from 'lodash/intersection'
import uniqBy from 'lodash/uniqBy'
import filter from 'lodash/filter'
import { useRouter } from 'next/router'
import { parseISO, subHours } from 'date-fns'
import { score } from 'fuzzaldrin'

import { isBefore, fallbackDate } from '@utils/date'
import useAgeRating from '@api/hooks/useAgeRating'
import {
  FeedObject,
  fetchFeed,
  fetchShows,
  ParentalGuidance,
  ShowObject,
} from '@api/resources'
import { Layout, LayoutIsLoading } from '@components/Layout'
import ItemsList from '@ui/ItemsList'
import Filters from '@components/ui/Filters'
import { Metadata, siteTitle } from '@components/Metadata'
import OverviewHeader from '@components/ui/OverviewHeader'
import WelcomeModal from '@components/ui/WelcomeModal'
import LogBook from '@components/ui/LogBook'
import { Panel } from '@components/Panel'
import { track } from '@components/AnalyticsProvider'

const mergeDedupe = (arr: string[][]) => {
  return [...new Set([].concat(...arr))]
}

const filterShows = (shows: ShowObject[]) => {
  const nowMinusOneHour = subHours(new Date(), 1)

  const filterLiveUnfinishedShows = (item: ShowObject) => {
    if (item.availabilityType !== 'live') return true

    return isBefore(
      nowMinusOneHour,
      parseISO(item.availabilityStartsAt ?? fallbackDate)
    )
  }
  return filter(shows, filterLiveUnfinishedShows)
}

const scoreShow = (show: ShowObject, query?: string) => {
  if (!query || query.length === 0) return 1.0

  const scores = [
    score(show.title.toLowerCase(), query) * 100,
    score(show.description.toLowerCase(), query) * 50,
    score((show.type || '').toLowerCase(), query) * 20,
    score(show.genres.join(' ').toLowerCase(), query) * 10,
    score(show.labels.join(' ').toLowerCase(), query) * 10,
    score(show.tags.join(' ').toLowerCase(), query) * 10,
    score(
      Object.values(show.credits)
        .map((p) => p.join(' '))
        .join(' ')
        .toLowerCase(),
      query
    ) * 10,
    score(show.body.toLowerCase(), query),
    score(show.availabilityType.toLowerCase(), query),
  ]

  return scores.reduce((a, b) => a + b, 0)
}

const scoreShows = (shows: ShowObject[], query?: string) => {
  return shows.map((show) => scoreShow(show, query))
}

export const getStaticProps: GetStaticProps = async () => {
  const [shows, feed] = await Promise.all([fetchShows(), fetchFeed()])

  return {
    props: { shows: filterShows(shows), feed },
    revalidate: 1,
  }
}

export interface OverviewPageProps {
  shows?: ShowObject[]
  feed?: FeedObject[]
}

const allAges: ParentalGuidance[] = ['12', '9', '6', 'AL']
const initialAges = (guidance?: string): ParentalGuidance[] => {
  if (guidance === 'AL') return ['AL']
  if (guidance === '6') return ['6', 'AL']
  if (guidance === '9') return ['9', '6', 'AL']
  return allAges
}

export default function OverviewPage({
  shows = [],
  feed = [],
}: OverviewPageProps) {
  const router = useRouter()
  const parentalGuidance = useAgeRating()
  const params = queryString.parse(router.asPath.replace('/overzicht', ''))

  const [query, setQuery] = useState<string>((params?.query as string) || '')
  const [type, setType] = useState<string>((params?.type as string) || 'all')
  const [genre, setGenre] = useState<string>((params?.genre as string) || 'all')

  const [ages, setAges] = useState<ParentalGuidance[]>(
    initialAges(parentalGuidance)
  )

  const types = useMemo(() => {
    return (uniqBy(shows, 'type') || [])
      .filter((s) => s.type && s.type !== '')
      .map((s) => ({
        label: s.type,
        value: s.type,
      }))
  }, [shows])

  const genres = useMemo(() => {
    const rawGenres = shows.map((s) => s.genres)

    return (mergeDedupe(rawGenres) || []).map((genre) => ({
      label: genre,
      value: genre,
    }))
  }, [shows])

  const filteredShows = useMemo(() => {
    return shows.filter((s) => {
      const ageGuidance = intersection(s.parentalGuidance, allAges)

      return [
        ageGuidance.length ? intersection(ageGuidance, ages).length > 0 : true,
        type !== 'all' ? s.type && s.type === type : true,
        genre !== 'all' ? s.genres && s.genres.includes(genre) : true,
      ].every((s) => s)
    })
  }, [shows, ages, type, genre])

  const scoredShows = useMemo(() => {
    const scores = scoreShows(filteredShows, query)
    const scored = filteredShows.map((show, idx) => ({
      show,
      score: scores[idx],
    }))

    return scored
      .filter((idx) => (query.length ? idx.score > 0.5 : true))
      .sort((idxa, idxb) => (idxa.score > idxb.score ? -1 : 1))
      .map((idx) => idx.show)
  }, [filteredShows, query])

  useEffect(() => {
    setAges(initialAges(parentalGuidance))
  }, [parentalGuidance, setAges])

  useEffect(() => {
    if (ages.includes('AL')) track('leeftijdAL')
    if (ages.includes('6')) track('leeftijd6')
    if (ages.includes('9')) track('leeftijd9')
    if (ages.includes('12')) track('leeftijd12')
  }, [ages])

  useEffect(() => {
    if (
      params.query !== query ||
      params.type !== type ||
      params.genre !== genre
    ) {
      const queryURL = queryString.stringify({
        query,
        genre,
        type,
      })
      const url = `${router.pathname}?${queryURL}`
      router.replace(url)
    }
  }, [
    query,
    genre,
    type,
    router.pathname,
    params.query,
    params.type,
    params.genre,
    router,
  ])

  if (router.isFallback) {
    return <LayoutIsLoading backgroundColor="blue" backgroundImage="grid" />
  }

  return (
    <Layout backgroundColor="blue" backgroundImage="grid">
      <Metadata>
        <title>{siteTitle} - Overzicht</title>
      </Metadata>
      <OverviewHeader />

      <Box
        sx={{
          height: 150,
          mt: -150,
          position: 'relative',
          zIndex: 1,
          background:
            'linear-gradient(180deg, rgba(87, 97, 236, 0) 0%, #5761EC 85.23%)',
        }}
      />
      <Box
        backgroundColor="blue"
        sx={{ position: 'relative', px: [4, 4, 4, 4, 7], pb: 6, zIndex: 5 }}
      >
        <Filters
          query={query}
          setQuery={setQuery}
          ages={ages}
          setAges={setAges}
          genre={genre}
          genres={genres}
          types={types}
          setGenre={setGenre}
          type={type}
          setType={setType}
          sx={{ pb: 6, mt: -50 }}
        />
        {scoredShows.length === 0 ? (
          <Text sx={{ color: 'white', textAlign: 'center', m: 6 }}>
            Geen items gevonden.
          </Text>
        ) : (
          <ItemsList shows={scoredShows} showBanners={false} />
        )}
      </Box>
      <WelcomeModal />
      <Panel
        aria-labelledby="Feed"
        side="left"
        name="feed"
        sx={{
          maxWidth: 720,
          p: 0,
        }}
      >
        <LogBook feed={feed} />
      </Panel>
    </Layout>
  )
}
