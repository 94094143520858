/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, Box, Flex, Text, Image } from 'theme-ui'
import { LinkButton } from '../Button'

import playLogo from '@styles/images/logo-cinekid-play.svg'
import headerImageDesktop from './cinekidheader-desktop.png'
import headerImageMobile from './cinekidheader-mobile.jpg'

const OverviewHeader = () => {
  return (
    <Fragment>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '80vh',
          minHeight: 500,
          userSelect: 'none',
          touchAction: 'none',
          backgroundImage: [
            `url(${headerImageMobile})`,
            `url(${headerImageDesktop})`,
          ],
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          zIndex: 3,
          px: 3,
          height: 'calc(80vh - 70px)',
          minHeight: 'calc(500px - 70px)',
          pb: 50,
        }}
      >
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Image
            src={playLogo}
            sx={{
              width: [240, 400, 500, 600],
            }}
            alt="Cinekid play logo"
          />
        </Flex>
        <Text
          variant="body"
          sx={{ textAlign: 'center', color: 'white', pt: 4, maxWidth: 460 }}
        >
          Ontdek de verschillende planeten vol met films, workshops en games.
        </Text>

        <LinkButton
          variant="secondary"
          label="Ga op reis"
          sx={{
            mt: 6,
          }}
          href="/ontdekken"
        />
        {/* <LinkButton
          variant="primary"
          label="Jouw MediaLab Werkjes"
          sx={{
            mt: 6,
          }}
          href="https://medialab-community.cinekid.nl/"
        /> */}
      </Flex>
    </Fragment>
  )
}

export default OverviewHeader
