/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { formatISO } from '@utils/date'

const Date = ({
  isoDateString,
  colorTime = 'white',
  ...rest
}: {
  isoDateString: string
  colorTime: string
}) => {
  const weekDay = formatISO(isoDateString, 'EEEE')
  const dayOfMonth = formatISO(isoDateString, 'd')
  const month = formatISO(isoDateString, 'LLLL')
  const time = formatISO(isoDateString, 'HH:mm')

  return (
    <Text
      as="time"
      variant="date"
      sx={{
        color: 'primary',
        br: {
          display: ['none', null, null, 'block'],
        },
      }}
      {...rest}
    >
      {weekDay} <br /> {dayOfMonth}
      &nbsp;
      {month} <br /> <span sx={{ color: colorTime }}>{time}</span>
    </Text>
  )
}

export default Date
