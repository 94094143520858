import useSessionStore, { SessionStore } from '../session'
import { selectProfile } from './useProfile'

export const selectProfileAgeRating = (store: SessionStore) => {
  return selectProfile(store)?.ageRating
}

function useAgeRating() {
  return useSessionStore(selectProfileAgeRating)
}

export default useAgeRating
