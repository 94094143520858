/** @jsx jsx */
import { jsx, Box, Heading, Text, Image } from 'theme-ui'
import { FeedObject } from '@api/resources/feed'
import Link from 'next/link'
import htmlStyles from '@styles/htmlStyles'

import { isBefore } from '@utils/date'
import { parseISO } from 'date-fns'
import { TrailerPlayer } from '@components/TrailerPlayer'

export const LogEntry = ({ entry, ...rest }: { entry: FeedObject }) => {
  const { title, videoUrl, image, collection } = entry

  const lastSeen = JSON.parse(localStorage.getItem('feed') || '{}').lastSeen
  const isRead = !isBefore(parseISO(entry.publicationDate), parseISO(lastSeen))

  return (
    <Box
      sx={{
        position: 'relative',
        bg: isRead ? 'red' : 'white',
        p: 3,
        color: isRead ? 'white' : 'dark',
      }}
      {...rest}
    >
      {title && (
        <Heading
          as="h3"
          variant="itemTitle"
          sx={{ fontSize: 3, color: isRead ? 'white' : 'red', mb: 2 }}
        >
          {title}
        </Heading>
      )}

      <Box
        sx={{
          fontSize: 1,
          lineHeight: 'body',
          ...htmlStyles,
          p: {
            fontSize: 1,
            lineHeight: 'body',
          },
          a: {
            color: 'yellow',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        }}
        dangerouslySetInnerHTML={{ __html: entry.body }}
      />

      {videoUrl && (
        <Box sx={{ mt: 3 }}>
          <TrailerPlayer image={image?.versions?.medium?.url} url={videoUrl} />
        </Box>
      )}

      {!videoUrl &&
        image &&
        (collection ? (
          <Link href={`/planeet/${collection.id}`} passHref>
            <a
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                mt: 3,
                display: 'block',
              }}
            >
              <Image
                src={image?.versions?.medium?.url}
                sx={{ maxWidth: '100%' }}
              />
            </a>
          </Link>
        ) : (
          <Image
            src={image?.versions?.medium?.url}
            sx={{ maxWidth: '100%', mt: 3 }}
          />
        ))}

      {collection && (
        <Text variant="mono" sx={{ fontSize: 1, mt: 2 }}>
          <Link href={`/planeet/${collection.id}`} passHref>
            <a
              sx={{
                color: isRead ? 'yellow' : 'red',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {collection.title}
            </a>
          </Link>
        </Text>
      )}
    </Box>
  )
}

export default LogEntry
