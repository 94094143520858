/** @jsx jsx */
import { jsx, Box, Flex, Heading, Text } from 'theme-ui'
import { orderBy } from 'lodash'
import { useEffect, useMemo } from 'react'

import bgGridImage from '@styles/images/bg-grid.svg'
import DateComponent from '@components/ui/Date'
import LogEntry from './LogEntry'
import Spacer from '@components/Spacer'
import { FeedObject } from '@api/resources/feed'
import useUIStore from '@stores/ui'

const DateEntry = ({ entry }: { entry: FeedObject }) => {
  return (
    <Flex
      key={`related-${entry.id}`}
      sx={{
        flexDirection: ['column', 'row'],
        mb: [4, 3],
      }}
    >
      <DateComponent
        isoDateString={entry.publicationDate}
        sx={{
          visibility: entry.sticky ? 'hidden' : 'visible',
          flexShrink: 0,
          color: 'white',
          span: { color: 'red' },
          br: {
            display: ['none', 'block'],
          },
          textAlign: ['left', 'right'],
        }}
      />
      <Spacer size={4} sizeVertical={2} />
      <LogEntry entry={entry} sx={{ flexGrow: 1 }} />
    </Flex>
  )
}

const LogBook = ({ feed, ...rest }: { feed: FeedObject[] }) => {
  const orderedFeed = useMemo(() => orderBy(feed, ['sticky'], ['desc']), [feed])
  const setUnread = useUIStore((s) => s.actions.setUnread)

  useEffect(() => {
    return () => {
      const feed = {
        lastSeen: new Date().toISOString(),
      }
      localStorage.setItem('feed', JSON.stringify(feed))
      setUnread(false)
    }
  })

  return (
    <Box
      sx={{
        color: 'white',
        p: 3,
        alignItems: 'center',
        background: `black url(${bgGridImage}) left top repeat`,
        overflow: 'auto',
        minHeight: '100%',
      }}
      {...rest}
    >
      <Flex sx={{ alignItems: 'center', mb: 3 }}>
        {/* date spacer */}
        <Text
          as="time"
          variant="date"
          sx={{ mr: 4, flexShrink: 0, flexGrow: 0, display: ['none', 'block'] }}
        />
        <Heading
          as="h2"
          variant="mono"
          sx={{ mb: 0, fontSize: 2, flexGrow: 1, lineHeight: '2em' }}
        >
          Nieuws
        </Heading>
      </Flex>

      <Box sx={{ flexGrow: 1 }}>
        {Object.keys(orderedFeed).length === 0 ? (
          <Flex sx={{ alignItems: 'center', mb: 3 }}>
            <Text
              as="time"
              variant="date"
              sx={{
                mr: 4,
                flexShrink: 0,
                flexGrow: 0,
                display: ['none', 'block'],
              }}
            />
            <Text>Nog geen nieuwe items.</Text>
          </Flex>
        ) : (
          orderedFeed.map((entry: FeedObject) => (
            <DateEntry key={`feed-${entry.id}`} entry={entry} />
          ))
        )}
      </Box>
    </Box>
  )
}

export default LogBook
