/** @jsx jsx */
import { jsx, Image, Text, Grid, Flex } from 'theme-ui'
import Link from 'next/link'
import { useCallback } from 'react'

import { Modal } from '@components/Modal'
import { Button } from '@ui/Button'
import logo from '@styles/images/logo-cinekid-play.svg'
import useUIStore from '@stores/ui'
import useBreakpoint from '@hooks/useBreakpoint'

const WelcomeModal = () => {
  const isTouch = useBreakpoint('only screen and (hover: none)')
  const toggleModal = useUIStore((state) => state.actions.toggleModal)

  const handleClose = useCallback(() => {
    toggleModal(undefined)
  }, [toggleModal])

  return (
    <Modal
      aria-labelledby="Welkom bij Cinekid Play!"
      name="welcome"
      backgroundColor="navy3d"
      borderColor="blue"
      backgroundImage="planet"
      narrow={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Image
          src={logo}
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            maxWidth: ['12em', '18em'],
            mb: 3,
          }}
        />

        <Text variant="body">
          Wat leuk dat je er bent! De nieuwste films en series staan voor je
          klaar. Trek je ruimtepak aan en spring mee!
        </Text>
        <Text variant="body">
          {isTouch
            ? `Sleep je vinger over het scherm om rond te kijken, en knijp om rond te vliegen van planeet naar planeet.`
            : `Klik en sleep met je muis om rond te kijken en gebruik de pijltjestoetsen om rond te vliegen van planeet naar planeet.`}
        </Text>

        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="secondary"
            label="Kijk even rond"
            sx={{ display: 'inline-block', flexGrow: 0, mb: 3, mr: 3 }}
            onClick={handleClose}
          />
        </Flex>
        <Grid sx={{ gridTemplateColumns: 'max-content max-content', mb: 5 }}>
          <Link href="/inloggen" passHref>
            <a
              sx={{
                color: 'primary',
                fontFamily: 'mono',
                cursor: 'pointer',
                textTransform: 'uppercase',
                borderWidth: 0,
                borderBottomWidth: '1px',
                borderBottomColor: 'primary',
                borderStyle: 'solid',
                '&:hover': { color: 'white', borderColor: 'transparent' },
              }}
            >
              Inloggen
            </a>
          </Link>
          <Link href="/ouders" passHref>
            <a
              sx={{
                color: 'primary',
                fontFamily: 'mono',
                cursor: 'pointer',
                textTransform: 'uppercase',
                borderWidth: 0,
                borderBottomWidth: '1px',
                borderBottomColor: 'primary',
                borderStyle: 'solid',
                '&:hover': { color: 'white', borderColor: 'transparent' },
              }}
            >
              Aanmelden
            </a>
          </Link>
        </Grid>
      </Grid>

      <Text
        variant="mono"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '2px solid white',
          borderTopColor: 'blue',
          color: 'white',
          display: 'flex',
          flexWrap: 'wrap',
          py: 0,
          fontSize: 1,
          a: {
            textAlign: 'center',
            boxSizing: 'border-box',
            display: 'inline-block',
            width: '50%',
            cursor: 'pointer',
            flexGrow: 0,
            flexShrink: 0,
            mx: 0,
            textDecoration: 'none',
            py: 1,
            px: 2,
            color: 'white',
            '&:hover': {
              textDecoration: 'underline',
            },
            '&:first-child': {
              borderRight: '2px solid white',
              borderRightColor: 'blue',
            },
          },
        }}
      >
        <Link href="/ouders" passHref>
          <a>Informatie voor ouders</a>
        </Link>
      </Text>
    </Modal>
  )
}

export default WelcomeModal
